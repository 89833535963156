@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Inter:wght@400;600;700&display=swap");
@import "./components/nav";
@import "./components/main";
@import "./components/work";
@import "./components/footer";
@import "./utilities/normalize";
@import "./utilities/reset";

body {
  font-family: "DM Sans", sans-serif;
  background-color: #161513;
  scroll-behavior: smooth;
}

.wrapper {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  padding: 0 24px;
}
