.main {
  height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  min-height: 600px;
  position: relative;

  header {
    max-width: 280px;
    width: 100%;

    h1 {
      font-size: 60px;
    }

    svg {
      position: absolute;
      bottom: 50px;
      left: 0;
    }
  }
}

.intro {
  text-align: right;
  font-size: 32px;
  font-weight: 300;
  max-width: 900px;
  line-height: 1.25;

  a {
    font-weight: 700;
  }

  .react {
    color: #3ebcff;
  }

  .sass {
    color: #cf649a;
  }
}
