.work {
  &-container {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    height: 100%;
    padding: 110px 0;
    border-bottom: 1px solid #c3c3c3;

    &--copy {
      width: 100%;
      height: 100%;
      letter-spacing: -0.6px;
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 50%;

      h4 {
        text-transform: uppercase;
      }

      .description {
        max-width: 400px;
      }

      .technology {
        color: #c9c9c9;
      }

      .link {
        height: 100%;

        a {
          text-decoration: underline;
        }
      }
    }

    .img {
      width: 50%;
    }
  }
}
