.nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 160px;
}

.logo {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -1px;
}

.list {
  display: flex;
  gap: 30px;

  li {
    list-style: none;

    a {
      text-decoration: none;
      color: #fff;
      font-size: 21px;
      letter-spacing: -0.6px;
    }
  }
}
