footer {
  display: flex;
  justify-content: space-between;
  padding: 100px 0 50px;

  .logo {
    h3 {
      font-size: 21px;
      font-weight: 400;
    }
  }

  .links {
    display: flex;
    align-items: center;

    li {
      margin: 0 8px;
      // svg {
      //   width: 50px;
      //   height: 50px;
      // }
    }
  }
}
